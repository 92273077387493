@use './config/' as *;
@forward "./main-scss/";
@forward "./listing/listing";
@forward "./listing/common-listing";
.waf-poll {
    overflow: unset;
    background: transparent;
    margin-inline: var(--space-2-neg);
    padding: var(--space-1);
    @extend %common-gradient;
    @extend %common-article-content;
    .waf-body {
        z-index: unset;
    }
    &::after {
        content: unset;
    }
    .waf-head {
        isolation: unset;
    }
    .head-wrap {
        padding-right: 11rem;
    }
    .title {
        font-size: 1.4rem;
    }
    .waf-body {
        @extend %px-2;
    }
    .login-redirect {
        @extend %mb-4;
        .text {
            @extend %font-14-pr;
        }
        .btn-site {
            @extend %p-1-2;
            @extend %font-14-pr;
        }
    }
    .waf-body {
        @extend %py-6;
    }
    iframe {
        aspect-ratio: 16/9;
        @extend %d-block;
        @extend %w-100;
        @extend %h-100;
    }
    .article {
        &-thumbnail {
            aspect-ratio: 16/9;
        }
        &-title {
            margin-bottom: 0;
            @include truncate(2, 14);
        }
        &-wrap {
            @extend %white-bg;
        }
        &-content {
            @extend %p-3;
            @extend %relative;
            &::after {
                content: "";
                opacity: 0;
                z-index: var(--z-negative);
                transition: opacity 500ms;
                background: linear-gradient(98.69deg, hsl(var(--hsl-secondary-light)/1) 14.5%, hsl(var(--hsl-primary)/1) 171.8%);
                @extend %position-t-l;
                @extend %w-100;
                @extend %h-100;
            }
        }
        &-item:hover {
            .article {
                &-content:after {
                    opacity: 1;
                }
                &-title {
                    @extend %white;
                }
            }
        }
    }
    .poll {
        &-item {
            flex-direction: row-reverse;
            background: linear-gradient(125.1deg, var(--club-primary) -53.54%, var(--club-secondary) 100%);
            @extend %flex-sb-c;
            @extend %relative;
            @extend %px-3;
            @extend %quarter-radius;
            @extend %hidden;
        }
        &-list {
            display: grid;
            @extend %my-4;
            @extend %gap-3;
        }
        &-terms {
            @extend %p-5-3;
            @extend %white-bg;
            .sub-title {
                width: max-content;
                @extend %font-16-pb;
                @extend %secondary-dark-bg-1;
                @extend %secondary-light;
                @extend %px-1;
                @extend %mb-2;
            }
            li {
                line-height: 1.5;
                @extend %secondary-dark;
                @extend %py-1;
                @extend %font-14;
            }
        }
    }
    .player {
        &-thumbnail {
            width: 12rem;
            height: 17.1rem;
            flex-shrink: 0;
            @extend %mr-1;
            img {
                object-position: bottom;
                @extend %h-100;
                @extend %w-100;
            }
        }
        &-position {
            position: absolute;
            top: var(--space-3);
            right: var(--space-3);
            .text {
                letter-spacing: .1rem;
                @include textstroke();
                @extend %secondary-1;
                @extend %font-24-pb;
            }
        }
        &-content {
            width: calc(100% - 12rem);
            @extend %white;
            .btn-site {
                @extend %quarter-radius;
                @extend %p-1-2;
                @extend %font-10;
            }
            .btn-text {
                @extend %font-10;
            }
            .last-name {
                font-weight: 700;
            }
        }
        &-name {
            @extend %mb-1;
            .name {
                line-height: 1.2;
                @extend %font-14;
            }
        }
        &-role {
            @extend %pb-2;
            @extend %mb-2;
            .role {
                width: max-content;
                line-height: 1.8;
                @extend %uppercase;
                @extend %px-2;
                @extend %font-10-pr;
                @extend %white-bg-1;
            }
        }
        &-result {
            .text {
                @extend %font-10;
            }
            .progressbar {
                height: 0.7rem;
                @extend %rounded-radius;
                @extend %white-bg-1;
                @extend %w-100;
                @extend %mt-1;
            }
            .progress {
                @extend %rounded-radius;
                @extend %w-10;
                @extend %primary-bg;
                @extend %d-block;
                @extend %h-100;
            }
        }
    }
    .club {
        &-img {
            width: 4rem;
            height: 4rem;
            flex-shrink: 0;
            @extend %flex-c-c;
            @extend %white-bg;
            @extend %circle-radius;
        }
        &-logo {
            width: 3rem;
            height: 3rem;
        }
        &-wrap {
            @extend %flex-n-c;
            @extend %gap-1;
            @extend %mb-3;
        }
        &-name {
            height: 2rem;
            @extend %mb-0;
            .name {
                @extend %font-9-pr;
            }
        }
    }
    .poll-disabled {
        opacity: 0.7;
        pointer-events: none
    }
}
.winner {
    &-section {
        @extend %mb-3;
        .player {
            &-name {
                .name {
                    @extend %font-20;
                }
            }
            &-result {
                .vote {
                    @extend %font-18-pb;
                }
            }
            &-role {
                padding-bottom: 0;
            }
            &-thumbnail {
                height: 20rem;
            }
        }
        .club {
            &-name {
                @extend %font-12-pm;
            }
            &-wrap {
                margin-bottom: var(--space-1);
                padding-bottom: var(--space-2);
                @include border(1, white, 2, bottom);
            }
        }
        .poll-item {
            isolation: isolate;
            &::before {
                content: "";
                width: 60%;
                height: 100%;
                background-repeat: no-repeat;
                background-size: cover;
                filter: invert(1);
                opacity: 0.2;
                transform: rotate(28deg);
                z-index: var(--z-negative);
                @include position(-35%, -20%);
                @include bgImg("svg/hexagon.svg");
            }
        }
    }
    &-container {
        @extend %mb-3;
    }
    &-label {
        width: max-content;
        filter: drop-shadow(0 .4rem .4rem hsl(var(--hsl-black)/0.2));
        @extend %px-3;
        @extend %ml-3-neg;
        @extend %mb-2;
        @extend %font-16-pb;
        @extend %secondary-light;
        @extend %accent-bg;
        @extend %relative;
        &::after,
        &::before {
            content: "";
            border: 1rem solid transparent;
            position: absolute;
            right: -1.9rem;
        }
        &::after {
            top: 0;
            border-top: 1rem solid var(--accent);
            border-left: 1rem solid var(--accent);
        }
        &::before {
            bottom: 0;
            border-bottom: 1rem solid var(--accent);
            border-left: 1rem solid var(--accent);
        }
    }
}
@include mq(col-md) {
    .waf-poll {
        .poll-list {
            @include grid(2, var(--space-3))
        }
        .login-redirect {
            text-align: right;
        }
        .club {
            &-name {
                display: block;
                height: auto;
            }
            &-wrap {
                padding-bottom: var(--space-3);
            }
        }
        .article-thumbnail {
            aspect-ratio: 21/9;
        }
        .title {
            font-size: 3rem;
        }
        .waf-body {
            padding-inline: 0;
        }
        .poll-terms {
            padding: var(--space-8);
            .sub-title {
                margin-bottom: var(--space-4);
            }
        }
        .player {
            &-content {
                padding-block: var(--space-1);
            }
            &-thumbnail {
                position: relative;
                bottom: var(--space-2-neg);
            }
        }
    }
    .voting-complete {
        @include grid(1, var(--space-4));
        .article-thumbnail {
            aspect-ratio: 16/9
        }
    }
    .winner {
        &-container {
            // @include grid(1, var(--space-4));
            .poll-item {
                height: 100%;
                margin: 0;
            }
            .player {
                &-name {
                    .name {
                        font-size: 2.8rem;
                    }
                }
                &-result {
                    .text {
                        font-size: 1.4rem;
                    }
                    .vote {
                        font-size: 4rem;
                    }
                }
                &-position {
                    .text {
                        font-size: 5rem;
                    }
                }
                &-thumbnail {
                    width: 50%;
                    height: 100%;
                }
                &-role {
                    margin-block: var(--space-3);
                    .role {
                        font-size: 1.2rem;
                    }
                }
                &-content {
                    width: calc(100% - 28rem);
                }
            }
            .club {
                &-name {
                    .name {
                        font-size: 1.4rem;
                    }
                }
                &-wra {
                    padding-bottom: var(--space-5);
                }
            }
        }
        &-section {
            margin-bottom: 0;
        }
        &-label {
            font-size: 2.6rem;
        }
    }
}
@include mq(col-lg) {
    .waf-poll {
        .poll-list {
            @include grid(3, var(--space-3))
        }
    }
    .voting-complete {
        @include grid(2, var(--space-4));
    }
}
@include mq(col-xl) {
    .waf-poll {
        .poll-list {
            @include grid(4, var(--space-3))
        }
    }
}